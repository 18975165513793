<template>
    <div class="section">
        <div class="container">
            <h1>Saved grilles</h1>

            <div class="is-flex is-flex-wrap-wrap">
                <o-field label="Type" class="mx-2">
                    <o-select v-model="filter.typeId" placeholder="All" v-if="options.types">
                        <option :value="null">All</option>
                        <option v-for="option in options.types" :value="option.Id" :key="option.Id">{{option.Name}}</option>
                    </o-select>
                </o-field>
                <o-field label="Style" class="mx-2">
                    <o-select v-model="filter.styleId" placeholder="All" v-if="options.styles">
                        <option :value="null">All</option>
                        <option v-for="option in options.styles" :value="option.Id" :key="option.Id">{{option.Name}}</option>
                    </o-select>
                </o-field>
                <o-field label="Material" class="mx-2">
                    <o-select v-model="filter.materialId" placeholder="All" v-if="options.materials">
                        <option :value="null">All</option>
                        <option v-for="option in options.materials" :value="option.Id" :key="option.Id">{{option.Name}}</option>
                    </o-select>
                </o-field>
                <o-field label="Finish" class="mx-2">
                    <o-select v-model="filter.finish" placeholder="All" v-if="options.finishes">
                        <option :value="null">All</option>
                        <option v-for="option in options.finishes" :value="option" :key="option">{{option}}</option>
                    </o-select>
                </o-field>
                <o-field label="Frame" class="mx-2">
                    <o-select v-model="filter.frameId" placeholder="All" v-if="options.frames">
                        <option :value="null">All</option>
                        <option v-for="option in options.frames" :value="option.Id" :key="option.Id">{{option.Name}}</option>
                    </o-select>
                </o-field>
                <o-button @click="ClearFilters" variant="info" inverted class="reset-filters" v-show="IsDefault()">
                    Clear All
                </o-button>
            </div>
            <div>

                <loading v-if="loading"></loading>

                <div class="is-flex is-flex-wrap-wrap" v-if="!loading">
                    <div class="is-flex flex-basis third-basis is-flex-grow-1" v-for="grille in filteredGrilles" :key="grille.Id">
                        <div class="equal-height is-flex-grow-1 card mx-2 my-2">
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-left">
                                        <figure class="image is-64x64">
                                            <img :src="api + 'api/image/' + grille.GrilleStyle.Style.Src" />
                                        </figure>
                                    </div>
                                    <div class="media-content">
                                        <p class="title is-4">{{grille.GrilleStyle.Style.Name}} <span v-if="grille.GrilleFrame?.FrameId"> - {{grille.GrilleFrame.Frame.Name}}</span></p>
                                        <p class="subtitle is-6">{{grille.Type.Name}}</p>
                                    </div>
                                </div>
                                <div class="content">
                                    <div style="white-space: nowrap;" v-for="size in grille.GrilleSizes" :key="size">
                                        Size: {{size.Length}}&quot;L&nbsp;&#215;&nbsp;{{size.Height}}&quot;H
                                        <span v-if="grille.GrilleThickness?.ThicknessId">&nbsp;&#215;{{grille.GrilleThickness.Thickness.Name}}</span>- {{size.Quantity}} grilles
                                    </div>
                                    <div style="white-space: nowrap;" v-for="size in grille.GrilleSizes" :key="size">
                                        Quantity: {{size.Quantity}} <br />
                                        {{size.Length}}&quot;L x {{size.Height}}&quot;H
                                        <span v-if="grille.GrilleThickness?.ThicknessId"> x {{grille.GrilleThickness.Thickness.Name}}</span>
                                    </div>
                                    <div>
                                        Material: {{grille.GrilleMaterial.Material.Name}}
                                    </div>
                                    <div>
                                        Finish: {{grille.GrilleFinish.Finish.Name}}
                                        <p v-show="grille.GrilleOption?.CustomFinish">{{grille.GrilleOption?.CustomFinish}}</p>
                                    </div>
                                    <div v-if="grille.GrillePatterns?.length > 0">
                                        <div v-for="pattern in grille.GrillePatterns" :key="pattern.PatternId">
                                            {{pattern.Pattern.Name}} - {{pattern.Size.Size}} <span v-if="pattern?.Detail?.Id"> Open Air {{pattern.Detail.PercentOpen}}%</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{grille.GrilleOption?.Application}}
                                        </div>
                                        <div v-if="grille.GrilleOption?.HasDoor">
                                            Door
                                        </div>
                                        <div v-if="grille.GrilleOption?.HasMountingHoles">
                                            Mounting holes
                                        </div>
                                        <div v-if="grille.GrilleOption?.HasRemovableCore">
                                            Removable core
                                        </div>
                                        <div v-if="grille.GrilleOption?.HasJBeadFrame">
                                            J-Bead Frame &reg;
                                        </div>
                                        <p>
                                            {{grille.GrilleOption?.SpecialRequirements}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="card-footer-item" @click="AddToQuote(grille)"><span style="white-space:nowrap"><o-icon icon="plus" style="vertical-align:middle"></o-icon> Add to current schedule</span></a>
                                <a class="card-footer-item" @click="Edit(grille)"><span style="white-space:nowrap"><o-icon icon="edit" style="vertical-align:middle"></o-icon>Edit</span></a>
                                <a class="card-footer-item" @click="TryRemove(grille)"><span style="white-space:nowrap"><o-icon icon="times" style="vertical-align:middle"></o-icon>Remove</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";

    export default {

        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const api = ref(process.env.VUE_APP_API);
            const loading = ref(true);
            const grilles = ref([]);

            const options = ref({});
            const filter = ref({
                typeId: null,
                styleId: null,
                materialId: null,
                finish: null,
                frameId: null
            });

            const filteredGrilles = computed(() => {
                return grilles.value.filter(x =>
                    (!filter.value.typeId || x.TypeId === filter.value.typeId) &&
                    (!filter.value.styleId || x.GrilleStyle.StyleId === filter.value.styleId) &&
                    (!filter.value.materialId || x.GrilleMaterial.MaterialId === filter.value.materialId) &&
                    (!filter.value.finish || x.GrilleFinish.Finish.Name === filter.value.finish) &&
                    (!filter.value.frameId || x.GrilleFrame?.FrameId === filter.value.frameId)
                );
            });

            const ClearFilters = () => {
                filter.value.typeId = null;
                filter.value.styleId = null;
                filter.value.materialId = null;
                filter.value.finish = null;
                filter.value.frameId = null;
            };

            const IsDefault = () => {
                return !!(filter.value.typeId || filter.value.styleId || filter.value.materialId
                    || filter.value.finish || filter.value.frameId);
            };

            const GetGrilles = async () => {
                try {
                    var response = await $http.get("/api/grille");
                    grilles.value = response.data;
                    return response;
                } catch (err) {
                    $error("Failed to load grilles.");
                    return Promise.reject(err);
                }
            };

            const GetGrilleTypes = () => {
                return $http.get("/api/grilleType");
            };
            const GetStyleTypes = () => {
                return $http.get("/api/grilleStyleType");
            };
            const GetMaterialTypes = () => {
                return $http.get("/api/grilleMaterialType");
            };
            const GetFinishTypes = () => {
                return $http.get("/api/grilleFinishType");
            };
            const GetFrameTypes = () => {
                return $http.get("/api/grilleFrameType");
            };

            const GetOptions = async () => {
                try {
                    const promises = [GetGrilleTypes(), GetStyleTypes(), GetMaterialTypes(), GetFinishTypes(), GetFrameTypes()];
                    var response = await Promise.all(promises);
                    options.value.types = response[0].data.sort((a, b) => a.Name > b.Name ? 1 : -1);
                    options.value.styles = response[1].data.sort((a, b) => a.Name > b.Name ? 1 : -1);
                    options.value.materials = response[2].data.sort((a, b) => a.Name > b.Name ? 1 : -1);
                    options.value.finishes = response[3].data.sort((a, b) => a.Name > b.Name ? 1 : -1).map(x => x.Name).filter((val, i, self) => self.indexOf(val) === i);
                    options.value.frames = response[4].data.sort((a, b) => a.Name > b.Name ? 1 : -1);
                    return response;
                }
                catch (err) {
                    return Promise.reject(err);
                }
            };

            const ToRFQGrille = (row) => {
                const grille = {
                    Id: row.Id,
                    TypeId: row.TypeId,
                    Type: row.Type,
                    GrilleFrame: row.GrilleFrame?.Frame || {},
                    GrilleStyle: row.GrilleStyle.Style,
                    GrilleMaterial: row.GrilleMaterial.Material,
                    GrilleFinish: row.GrilleFinish.Finish,
                    GrilleThickness: !row.GrilleThickness?.Thickness ? {} : { ...row.GrilleThickness.Thickness },
                    GrillePatterns: row.GrillePatterns.length == 0 ? {} : { ...row.GrillePatterns[0], Name: row.GrillePatterns[0].Size.Size + ' Open Air ' + row.GrillePatterns[0].Detail.PercentOpen + '%', GrilleId: 0 },
                    GrilleSizes: row.GrilleSizes.slice(),
                    GrilleOption: { ...row.GrilleOption }
                };

                //clear IDs
                if (grille.GrilleOption.GrilleId) {
                    grille.GrilleOption.GrilleId = 0;
                }
                if (grille.GrillePatterns.GrilleId) {
                    grille.GrillePatterns.GrilleId = 0;
                }
                for (let i = 0; i < grille.GrilleSizes.length; i++) {
                    const size = grille.GrilleSizes[i];
                    if (size.GrilleId) {
                        size.GrilleId = 0;
                    }
                }

                return grille;
            };

            const AddToQuote = (row) => {
                const grille = ToRFQGrille(row);
                global.$store.dispatch("addGrille", grille);
                //Remove();
                $success("Added to quote.");
            };
            const TryRemove = async (row) => {
                if (confirm("Are you sure you want to remove this grille?")) {  //TODO: Update this with oruga confirm dialog (currently doesn't exist in docs)
                    await Remove(row);
                    const i = grilles.value.indexOf(row);
                    grilles.value.splice(i, 1);
                    $success("Removed grille.");
                }
            };
            const Remove = async (row) => {
                try {
                    return await $http({ method: "DELETE", url: "/api/grille", headers: { 'Content-Type': 'application/json' }, data: { Ids: [row.Id] } });
                }
                catch (err) {
                    $error("Failed to remove.");
                    return Promise.reject(err);
                }
            };
            const Edit = (row) => {
                sessionStorage.setItem("editGrille", JSON.stringify(ToRFQGrille(row)));
                global.$router.push({ name: "rfq" });
            };

            onMounted(async () => {
                await GetGrilles();
                await GetOptions();
                loading.value = false;
            });

            const TotalAmount = (row) => {
                let total = 0;
                for (const size of row.GrilleSizes) {
                    if (size.Quantity) {
                        total += parseInt(size.Quantity);
                    }
                }
                return total;
            };

            return {
                api,
                loading,
                grilles,
                filteredGrilles,
                filter,
                options,
                AddToQuote,
                TryRemove,
                Edit,
                ClearFilters,
                IsDefault,
                TotalAmount
            }
        }
    }
</script>
<style>
    .reset-filters {
        align-self: flex-end;
        margin: 0 0.5rem 0.75rem 0.5rem;
    }
</style>
